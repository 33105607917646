.row{
    display: flex;

flex-direction:row ;
flex-wrap: wrap;
}
.title{
    direction: rtl;
    font-family: "Assistant";
    font-size: 3rem;
    margin:2% auto;
    text-align: center;
  
   
}
@media only screen and (max-width: 350px) {
    .row{
        display: flex;
    
    flex-direction:row ;
    flex-wrap: wrap;
    }
    .title{
        direction: rtl;
        font-family: "Assistant";
        font-size: 1.8rem;
        margin:2% auto;
        text-align: center;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {
    .row{
        display: flex;
    
    flex-direction:row ;
    flex-wrap: wrap;
    }
    .title{
        direction: rtl;
        font-family: "Assistant";
        font-size: 2rem;
        margin:2% auto;
        text-align: center;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    }
}