.title{
  direction: rtl;
  font-family: "Assistant";
  font-size: 3rem;
  color:  rgb(75, 5, 140);
  width: 70%;    
  font-style: italic;
  margin: 2% auto;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.description{
  direction: rtl;
  font-family: "AssistantR";
  width: 85%; 
  font-size: 1.4rem;
  margin: 2% auto;
  text-align: center;
}

.more{
  direction: rtl;
  font-family: "Assistant";
  width: 70%; 
  font-size: 1.5rem;
  margin: 2% auto;
  text-align: center;
}

.text {
  display: flex;
  align-items: flex-start; /* Make sure items align at the top */
  justify-content: flex-start; /* Make sure items start at the beginning */
  direction: rtl;
  margin: 0;
  padding: 5px;
}

.wrap{
  width: 40%; 
  justify-content: center;
  margin: 0 auto;
}

.checkmark{
  font-size: 30px; 
  color: rgb(129, 7, 153); 

  margin-right: 10px; /* Adjust this value as needed for spacing */
}

@media only screen and (max-width: 350px) {

  .title{
      font-size: 2rem;
      width: 90%;    
  }
  .description{
      width: 90%; 
  }
  .more{
      font-size: 1.2rem;
      width: 70%; 
  }
  .wrap{
      width: 95%; 
  }
  .checkmark{
      font-size: 20px; 
  }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {

  .title{
      font-size: 2rem;
      width: 95%;    
  }
  .description{
      width: 90%; 
      font-size: 1.2rem;
  }
  .more{
      font-size: 1.4rem;
      width: 80%; 
  }
  .wrap{
      width: 95%; 
  }
  .checkmark{
      font-size: 25px; 
  }
}

@media only screen and (min-width: 450px) and (max-width: 850px) {

  .title{
      font-size: 2.5rem;
      width: 90%;    
  }
  .description{
      width: 90%; 
  }
  .more{
      font-size: 1.5rem;
      width: 80%; 
  }
  .wrap{
      width: 90%; 
  }
  .checkmark{
      font-size: 25px; 
  }
}

@media only screen and (min-width: 850px) and (max-width: 1150px) {

  .title{
      font-size: 2.8rem;
      width: 90%;    
  }
  .description{
      width: 90%; 
  }
  .more{
      font-size: 1.8rem;
      width: 80%; 
  }
  .wrap{
      width: 70%; 
  }
  .checkmark{
      font-size: 25px; 
  }
}
