.wrap{
    display: flex;
    width:450px;
    height:200px;
    flex-direction: column;
    box-shadow: 0 0 4px 1px #3e3e3ec8;
    border-radius: 10px 0 10px 0;
    margin:20px auto;
}
.number{
    display: flex;
    width:90%;
    text-align: right;
    font-family: "Assistant";
    direction: rtl;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.637);
    font-size:1.8rem;
margin:2% auto
}
.description{
    display: flex;
    width:90%;
    direction: rtl;
    text-align: right;
    font-family: "AssistantR";
    font-size:1.2rem;
    margin:2% auto
}


@media only screen and (max-width: 350px) {

    .wrap{
        display: flex;
        width:100%;
        height:150px;
        flex-direction: column;
        box-shadow: 0 0 4px 1px #3e3e3ec8;
        border-radius: 10px 0 10px 0;
        margin:20px auto;
    }
    .number{
        display: flex;
        width:90%;
        text-align: right;
        font-family: "Assistant";
        direction: rtl;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.637);
        font-size:1.2rem;
    margin:2% auto
    }
    .description{
        display: flex;
        width:90%;
        direction: rtl;
        text-align: right;
        font-family: "AssistantR";
        font-size:1rem;
        margin:2% auto
    }

}
@media only screen and (min-width: 350px) and (max-width: 450px) {

    .wrap{
        display: flex;
        width:100%;
        height:180px;
        flex-direction: column;
        box-shadow: 0 0 4px 1px #3e3e3ec8;
        border-radius: 10px 0 10px 0;
        margin:20px auto;
    }
    .number{
        display: flex;
        width:90%;
        text-align: right;
        font-family: "Assistant";
        direction: rtl;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.637);
        font-size:1.4rem;
    margin:2% auto
    }
    .description{
        display: flex;
        width:90%;
        direction: rtl;
        text-align: right;
        font-family: "AssistantR";
        font-size:1rem;
        margin:2% auto
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {

    .wrap{
        display: flex;
        width:100%;
        height:200px;
        flex-direction: column;
        box-shadow: 0 0 4px 1px #3e3e3ec8;
        border-radius: 10px 0 10px 0;
        margin:20px auto;
    }
    .number{
        display: flex;
        width:90%;
        text-align: right;
        font-family: "Assistant";
        direction: rtl;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.637);
        font-size:1.5rem;
    margin:2% auto
    }
    .description{
        display: flex;
        width:90%;
        direction: rtl;
        text-align: right;
        font-family: "AssistantR";
        font-size:1.1rem;
        margin:2% auto
    }
}