.input {
    font-family: "AssistantR";
    font-size: 18px;
    width:400px;
    height: 50px;
    direction: rtl;
    border: none;
    margin: 4% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.3s ease-in-out;
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  
  .input:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
    background: linear-gradient(to right, #50139a65, #fffbaf90);
  }

  
  .input::placeholder {
    color: #999;
  }
  .checkboxContainer {
    display: flex;
    align-items: center;
    margin: 10px 0;
    direction: rtl;
  }
  
  .checkboxContainer input[type="checkbox"] {
    margin-left: 10px;
  }
  
  .checkboxContainer label {
    font-size: 0.95rem;
    color: #000000;
    font-family: "Assistant";
  }
  

  .button {
    font-family: "AssistantR";
    font-size: 18px;
    width: 300px;
    height: 50px;
    direction: rtl;
    border: none;
    cursor: pointer;
    margin: 3% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.5s ease-in-out;
    
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  .button:hover {
transform: scale(1.1);
  }
  .button:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
    background: linear-gradient(to right, #50139a65, #fffbaf90);
  }
.title{
    font-family: "Fredoka";
    font-size: 3rem;
    width:60%;
    text-align: center;
direction: rtl;
color:rgb(66, 13, 116);
text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
margin:0 auto;
}
.subTitle{
    font-family: "AssistantR";
    font-size: 1.5rem;
    width:40%;
    margin:2% auto 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
direction: rtl;
}
.form{
    display: flex;
    justify-content: center;
    width:70%;
   
margin:2% auto;
}
.input::placeholder{
    font-family: "AssistantR";
    font-size: 16px;
  color: rgba(0, 0, 0, 0.6); 
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@media only screen and (max-width: 350px) {
  .input {
    font-family: "AssistantR";
    font-size: 16px;
    width:280px;
    height: 40px;
    direction: rtl;
    border: none;
    margin: 4% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.3s ease-in-out;
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  
  .input:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
     background: linear-gradient(to right, #50139a65, #fffbaf90);
  }

  
  .input::placeholder {
    color: #999;
  }

  .button {
    font-family: "AssistantR";
    font-size: 18px;
    width: 240px;
    height: 40px;
    direction: rtl;
    border: none;
    cursor: pointer;
    margin: 3% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.5s ease-in-out;
    
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  .button:hover {
transform: scale(1.1);
  }
  .button:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
    background: linear-gradient(to right, #50139a65, #fffbaf90);
  }
.title{
    font-family: "Fredoka";
    font-size: 1.5rem;
    text-align: center;
direction: rtl;
width:90%;
text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
margin:0 auto;
}
.subTitle{
    font-family: "AssistantR";
    font-size: 1.2rem;
    width:80%;
    margin:2% auto 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
direction: rtl;
}
.form{
    display: flex;
    justify-content: center;
    width:70%;
   
margin:2% auto;
}
.input::placeholder{
    font-family: "AssistantR";
    font-size: 16px;
  color: rgba(0, 0, 0, 0.6); 
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
}

@media only screen  and (min-width: 350px) and (max-width: 450px) {
  .input {
    font-family: "AssistantR";
    font-size: 16px;
    width:280px;
    height: 40px;
    direction: rtl;
    border: none;
    margin: 4% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.3s ease-in-out;
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  
  .input:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
     background: linear-gradient(to right, #50139a65, #fffbaf90);
  }

  
  .input::placeholder {
    color: #999;
  }

  .button {
    font-family: "AssistantR";
    font-size: 18px;
    width: 240px;
    height: 40px;
    direction: rtl;
    border: none;
    cursor: pointer;
    margin: 3% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.5s ease-in-out;
    
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  .button:hover {
transform: scale(1.1);
  }
  .button:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
    background: linear-gradient(to right, #50139a65, #fffbaf90);
  }
.title{
    font-family: "Fredoka";
    font-size: 1.5rem;
    text-align: center;
direction: rtl;
width:80%;
text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
margin:2% auto;
}
.subTitle{
    font-family: "AssistantR";
    font-size: 1.2rem;
    width:80%;
    margin:2% auto 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
direction: rtl;
}
.form{
    display: flex;
    justify-content: center;
    width:70%;
   
margin:2% auto;
}
.input::placeholder{
    font-family: "AssistantR";
    font-size: 16px;
  color: rgba(0, 0, 0, 0.6); 
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
}
@media only screen  and (min-width: 450px) and (max-width: 550px) {
  .input {
    font-family: "AssistantR";
    font-size: 16px;
    width:280px;
    height: 40px;
    direction: rtl;
    border: none;
    margin: 4% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.3s ease-in-out;
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  
  .input:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
     background: linear-gradient(to right, #50139a65, #fffbaf90);
  }

  
  .input::placeholder {
    color: #999;
  }

  .button {
    font-family: "AssistantR";
    font-size: 18px;
    width: 240px;
    height: 40px;
    direction: rtl;
    border: none;
    cursor: pointer;
    margin: 3% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.5s ease-in-out;
    
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  .button:hover {
transform: scale(1.1);
  }
  .button:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
    background: linear-gradient(to right, #50139a65, #fffbaf90);
  }
.title{
    font-family: "Fredoka";
    font-size: 1.8rem;
    text-align: center;
direction: rtl;
text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
margin:0 auto;
width:90%;
}
.subTitle{
    font-family: "AssistantR";
    font-size: 1.2rem;
    width:80%;
    margin:2% auto 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
direction: rtl;
}
.form{
    display: flex;
    justify-content: center;
    width:70%;
   
margin:2% auto;
}
.input::placeholder{
    font-family: "AssistantR";
    font-size: 16px;
  color: rgba(0, 0, 0, 0.6); 
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
}
@media only screen  and (min-width: 550px) and (max-width: 650px) {
  .input {
    font-family: "AssistantR";
    font-size: 18px;
    width:320px;
    height: 50px;
    direction: rtl;
    border: none;
    margin: 4% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.3s ease-in-out;
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  
  .input:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
     background: linear-gradient(to right, #50139a65, #fffbaf90);
  }

  
  .input::placeholder {
    color: #999;
  }

  .button {
    font-family: "AssistantR";
    font-size: 18px;
    width: 300px;
    height: 50px;
    direction: rtl;
    border: none;
    cursor: pointer;
    margin: 3% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.5s ease-in-out;
    
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  .button:hover {
transform: scale(1.1);
  }
  .button:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
    background: linear-gradient(to right, #50139a65, #fffbaf90);
  }
.title{
    font-family: "Fredoka";
    font-size: 2rem;
    text-align: center;
direction: rtl;
width:90%;
text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
margin:0 auto;
}
.subTitle{
    font-family: "AssistantR";
    font-size: 1.2rem;
    width:80%;
    margin:2% auto 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
direction: rtl;
}
.form{
    display: flex;
    justify-content: center;
    width:70%;
   
margin:2% auto;
}
.input::placeholder{
    font-family: "AssistantR";
    font-size: 16px;
  color: rgba(0, 0, 0, 0.6); 
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
}
@media only screen  and (min-width: 650px) and (max-width: 850px) {
  .input {
    font-family: "AssistantR";
    font-size: 18px;
    width:350px;
    height: 50px;
    direction: rtl;
    border: none;
    margin: 4% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.3s ease-in-out;
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  
  .input:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
     background: linear-gradient(to right, #50139a65, #fffbaf90);
  }

  
  .input::placeholder {
    color: #999;
  }

  .button {
    font-family: "AssistantR";
    font-size: 18px;
    width: 320px;
    height: 50px;
    direction: rtl;
    border: none;
    cursor: pointer;
    margin: 3% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.5s ease-in-out;
    
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  .button:hover {
transform: scale(1.1);
  }
  .button:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
  }
.title{
    font-family: "Fredoka";
    font-size: 2.2rem;
    text-align: center;
direction: rtl;
text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
margin:0 auto;
width:80%;
}
.subTitle{
    font-family: "AssistantR";
    font-size: 1.2rem;
    width:80%;
    margin:2% auto 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
direction: rtl;
}
.form{
    display: flex;
    justify-content: center;
    width:70%;
   
margin:2% auto;
}
.input::placeholder{
    font-family: "AssistantR";
    font-size: 16px;
  color: rgba(0, 0, 0, 0.6); 
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
}
@media only screen  and (min-width: 850px) and (max-width: 1050px) {
  .input {
    font-family: "AssistantR";
    font-size: 18px;
    width:350px;
    height: 50px;
    direction: rtl;
    border: none;
    margin: 4% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.3s ease-in-out;
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  
  .input:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
     background: linear-gradient(to right, #50139a65, #fffbaf90);
  }

  
  .input::placeholder {
    color: #999;
  }

  .button {
    font-family: "AssistantR";
    font-size: 18px;
    width: 320px;
    height: 50px;
    direction: rtl;
    border: none;
    cursor: pointer;
    margin: 3% auto;
    border-radius: 8px;
     background: linear-gradient(270deg, #d8b9f2 0%, #b3e5fc 100%);
    box-shadow: inset 0 1px 5px rgba(0, 0, 0, 0.1), 0 0 8px 1px #888888;
    transition: all 0.5s ease-in-out;
    
    padding: 0 15px;
    outline: none;
    color: #333;
  }
  .button:hover {
transform: scale(1.1);
  }
  .button:focus {
    box-shadow: 0 0 0 7px 1px rgba(105, 105, 105, 0.3);
    background: linear-gradient(to right, #50139a65, #fffbaf90);
  }
.title{
    font-family: "Fredoka";
    font-size: 2.6rem;
    text-align: center;
direction: rtl;
text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
margin:0 auto;
width:80%;
}
.subTitle{
    font-family: "AssistantR";
    font-size: 1.2rem;
    width:80%;
    margin:2% auto 0;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    text-align: center;
direction: rtl;
}
.form{
    display: flex;
    justify-content: center;
    width:70%;
   
margin:2% auto;
}
.input::placeholder{
    font-family: "AssistantR";
    font-size: 16px;
  color: rgba(0, 0, 0, 0.6); 
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
}