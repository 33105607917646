

.description{
    direction: rtl;
    font-family: "AssistantR";
    width:75%; 
    font-size: 1.4rem;
    margin:1% auto;
    line-height: 2;
    text-align: center;
  }
  .bold{
    direction: rtl;
    font-family: "AssistantR";
    width:75%; 
    font-size: 1.6rem;
    margin:1% auto;
    line-height: 2;
    text-align: center;
  }
.header{
  direction: rtl;
  font-family: "Assistant";
  width:75%; 
  font-size: 2rem;
  margin:1% auto;
  text-align: center;
}
  .center{
    display: flex;
    justify-content: center;
    width:30%;
    margin:0 auto;
  }
  .image{
    width:100%;
    object-fit: contain;
  }
  .more{
    direction: rtl;
    font-family: "Fredoka";
    font-size: 2rem;
    color:rgb(66, 13, 116);
    margin:2% auto;
    text-align: center;
  }
  .title{
    direction: rtl;
    font-family: "Fredoka";
    font-size: 2.5rem;
    margin:2% auto;
    color:rgb(66, 13, 116);
    text-align: center;
  }


  @media only screen and (max-width: 350px) {
    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 1.5rem;
      width:95%;    
      margin:2% auto;
      text-align: center;
    }
    .header{
      direction: rtl;
      font-family: "Assistant";
      width:75%; 
      font-size: 1.4rem;
      margin:1% auto;
      text-align: center;
    }
  .description{
      direction: rtl;
      font-family: "AssistantR";
      width:90%; 
      font-size: 1.1rem;
      margin:2% auto;
      text-align: center;
    }
    .bold{
      direction: rtl;
      font-family: "AssistantR";
      width:75%; 
      font-size: 1.3rem;
      margin:1% auto;
      line-height: 2;
      text-align: center;
    }
    .center{
      display: flex;
      justify-content: center;
      width:90%;
      margin:0 auto;
    }
    .image{
      width:100%;
      object-fit: contain;
    }
    .more{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 1.5rem;
      margin:2% auto;
      text-align: center;
    }
 

  }
  @media only screen  and (min-width: 350px) and (max-width: 450px) {
    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 1.7rem;
      width:95%;    
      margin:2% auto;
      text-align: center;
    }
    .bold{
      direction: rtl;
      font-family: "AssistantR";
      width:75%; 
      font-size: 1.4rem;
      margin:1% auto;
      line-height: 2;
      text-align: center;
    }
    .header{
      direction: rtl;
      font-family: "Assistant";
      width:75%; 
      font-size: 1.6rem;
      margin:1% auto;
      text-align: center;
    }
  .description{
      direction: rtl;
      font-family: "AssistantR";
      width:90%; 
      font-size: 1.2rem;
      margin:2% auto;
      text-align: center;
    }
    .center{
      display: flex;
      justify-content: center;
      width:85%;
      margin:0 auto;
    }
    .image{
      width:100%;
      object-fit: contain;
    }
    .more{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 1.6rem;
      margin:2% auto;
      text-align: center;
    }
  }
  @media only screen  and (min-width: 450px) and (max-width: 550px) {
    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 1.9rem;
      width:95%;    
      margin:2% auto;
      text-align: center;
    }
    .bold{
      direction: rtl;
      font-family: "AssistantR";
      width:75%; 
      font-size: 1.5rem;
      margin:1% auto;
      line-height: 2;
      text-align: center;
    }
    .header{
      direction: rtl;
      font-family: "Assistant";
      width:75%; 
      font-size: 1.7rem;
      margin:1% auto;
      text-align: center;
    }
  .description{
      direction: rtl;
      font-family: "AssistantR";
      width:90%; 
      font-size: 1.25rem;
      margin:2% auto;
      text-align: center;
    }
    .center{
      display: flex;
      justify-content: center;
      width:80%;
      margin:0 auto;
    }
    .image{
      width:100%;
      object-fit: contain;
    }
    .more{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 1.7rem;
      margin:2% auto;
      text-align: center;
    }
  }
  @media only screen  and (min-width: 550px) and (max-width: 650px) {
   
    .description{
      direction: rtl;
      font-family: "AssistantR";
      width:90%; 
      font-size: 1.25rem;
      margin:3% auto;
      text-align: center;
    }
    .header{
      direction: rtl;
      font-family: "Assistant";
      width:75%; 
      font-size: 1.8rem;
      margin:1% auto;
      text-align: center;
    }
    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 2rem;
      width:95%;    
      margin:2% auto;
      text-align: center;
    }
    .center{
      display: flex;
      justify-content: center;
      width:70%;
      margin:0 auto;
    }
    .image{
      width:100%;
      object-fit: contain;
    }
   
  }
  @media only screen  and (min-width: 650px) and (max-width: 850px) {
    .description{
      direction: rtl;
      font-family: "AssistantR";
      width:70%; 
      font-size: 1.25rem;
      margin:2% auto;
      text-align: center;
    }
    .header{
      direction: rtl;
      font-family: "Assistant";
      width:75%; 
      font-size: 1.8rem;
      margin:1% auto;
      text-align: center;
    }
    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 2.1rem;
      width:95%;    
      margin:2% auto;
      text-align: center;
    }
    .center{
      display: flex;
      justify-content: center;
      width:60%;
      margin:0 auto;
    }
    .image{
      width:100%;
      object-fit: contain;
    }
   
  }
  @media only screen  and (min-width: 850px) and (max-width: 1050px) {
    .description{
      direction: rtl;
      font-family: "AssistantR";
      width:70%; 
      font-size: 1.25rem;
      margin:2% auto;
      text-align: center;
    }
    .header{
      direction: rtl;
      font-family: "Assistant";
      width:75%; 
      font-size: 1.9rem;
      margin:1% auto;
      text-align: center;
    }
    .title{
      direction: rtl;
      font-family:"Fredoka";
      font-size: 2.3rem;
      width:95%;    
      margin:2% auto;
      text-align: center;
    }
    .center{
      display: flex;
      justify-content: center;
      width:50%;
      margin:0 auto;
    }
    .image{
      width:100%;
      object-fit: contain;
    }
   
  }
  @media only screen  and (min-width: 1050px) and (max-width: 1250px) {
    .description{
      direction: rtl;
      font-family: "AssistantR";
      width:70%; 
      font-size: 1.25rem;
      margin:2% auto;
      text-align: center;
    }
    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 2.5rem;
      width:90%;    
      margin:2% auto;
      text-align: center;
    }
    .center{
      display: flex;
      justify-content: center;
      width:40%;
      margin:0 auto;
    }
    .image{
      width:100%;
      object-fit: contain;
    }
   
  }