@font-face {
    font-family: 'Assistant';
    src: url('./Assistant-VariableFont_wght.ttf') format('truetype');
    font-weight:800;
    font-style: normal;
  }
  @font-face {
    font-family: 'AssistantR';
    src: url('./Assistant-VariableFont_wght.ttf') format('truetype');
    font-weight:light;
    font-style: normal;
  }

@font-face {
  font-family: 'AssistantL';
  src: url('./Assistant-VariableFont_wght.ttf') format('truetype');
  font-weight:100;
  font-style: normal;
}
  @font-face {
    font-family: 'Fredoka';
    src: url('./Fredoka-VariableFont_wdth\,wght.ttf') format('truetype');
    font-weight:bold;
    font-style: normal;
  }
  @font-face {
    font-family: 'Dragon';
    src: url('./Camping\ Bold.ttf') format('truetype');
    font-weight:800;
    font-style: normal;
  }
  @font-face {
    font-family: 'Karantina';
    src: url('./Karantina-Bold.ttf') format('truetype');
    font-weight:800;
    font-style: normal;
  }
