.title{
    direction: rtl;
    font-family: "Fredoka";
    font-size: 3rem;
    margin:2% auto;
    color:rgb(66, 13, 116);
    text-align: center;
    
    z-index: -1;
    position: relative;
}
.header{
  direction: rtl;
  font-family: "Fredoka";
  font-size: 3.5rem;
  color:rgb(66, 13, 116);
  margin:2% auto;
  text-align: center;
  
  z-index: -1;
  position: relative;
}
.bold {
    background:rgb(255, 255, 255);
    display: inline;
    padding: 0.2rem 0.5rem;
    border-radius: 0.5rem;
    color: #050505;
 
    
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .bold::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: whitesmoke;
    z-index: -1;
    border-radius: 0.5rem;
    filter: blur(5px);
    opacity: 0.5;
  }

  .description{
    color:rgb(66, 13, 116);
    direction: rtl;
    font-family: "Fredoka";
    font-size: 3rem;
    line-height: 1.5;
    margin:2% auto;
    text-align: center;
    z-index: -1;
    position: relative;

  }
  .descriptionP{
    color:rgb(66, 13, 116);
    direction: rtl;
    font-family: "Fredoka";
    font-size: 3rem;
    line-height: 1.5;
    margin:2% auto;
    text-align: center;
    z-index: -1;
    padding-top:80px;
    position: relative;

  }
.explain{
  
  direction: rtl;
  font-family: "AssistantR";
  font-size: 1.2rem;
  line-height: 1.5;
  margin:2% auto;
  text-align: center;
  z-index: -1;


}
  @media only screen and (max-width: 350px) {

    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 1.5rem;
      margin:3% auto;
      text-align: center;
      
  }
  .header{
    direction: rtl;
    font-family: "Fredoka";
    font-size:1.8rem;
    margin:2% auto;
    text-align: center;
    
    z-index: -1;
    position: relative;
  }
  .bold {
      background:rgb(255, 255, 255);
      display: inline;
      padding: 0.2rem 0.5rem;
      border-radius: 0.5rem;
      color: #050505;
      
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      position: relative;
    }
    
    .bold::before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      background: whitesmoke;
      z-index: -1;
      border-radius: 0.5rem;
      filter: blur(5px);
      opacity: 0.5;
    }
  
    .description{
  line-height: 1.5;
      direction: rtl;
      font-family: "Fredoka";
      width:85%;
      font-size: 1.5rem;
      margin:2% auto;
      text-align: center;
  
    }
    .descriptionP{
      line-height: 1.5;
          direction: rtl;
          font-family: "Fredoka";
          width:85%;
          padding-top:80px;
          font-size:1.5rem;
          margin:2% auto;
          text-align: center;
      
        }
  }
  @media only screen  and (min-width: 350px) and (max-width: 450px) {

    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 1.5rem;
      margin:3% auto;
      text-align: center;
      
  }
  .header{
    direction: rtl;
    font-family: "Fredoka";
    font-size: 2.2rem;
    margin:2% auto;
    text-align: center;
    
    z-index: -1;
    position: relative;
  }
  .bold {
      background:rgb(255, 255, 255);
      display: inline;
      padding: 0.2rem 0.5rem;
      border-radius: 0.5rem;
      color: #050505;
      
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      position: relative;
    }
    
    .bold::before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      background: whitesmoke;
      z-index: -1;
      border-radius: 0.5rem;
      filter: blur(5px);
      opacity: 0.5;
    }
  
    .description{
  line-height: 1.5;
      direction: rtl;
      font-family: "Fredoka";
      width:90%;
      font-size: 1.6rem;
      margin:2% auto;
      text-align: center;
  
    }
    .descriptionP{
      line-height: 1.5;
      direction: rtl;
      font-family: "Fredoka";
      width:90%;
      font-size: 1.6rem;
      margin:2% auto;
      text-align: center;
          padding-top:80px;
  
      
        }
  }

  @media only screen  and (min-width: 450px) and (max-width: 550px) {

    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 2rem;
      margin:3% auto;
      text-align: center;
      
  }
  .header{
    direction: rtl;
    font-family: "Fredoka";
    font-size: 2.5rem;
    margin:2% auto;
    text-align: center;
    
    z-index: -1;
    position: relative;
  }
  .bold {
      background:rgb(255, 255, 255);
      display: inline;
      padding: 0.2rem 0.5rem;
      border-radius: 0.5rem;
      color: #050505;
      
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      position: relative;
    }
    
    .bold::before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      background: whitesmoke;
      z-index: -1;
      border-radius: 0.5rem;
      filter: blur(5px);
      opacity: 0.5;
    }
  
    .description{
  line-height: 1.5;
      direction: rtl;
      font-family: "Fredoka";
      width:85%;
      font-size: 1.5rem;
      margin:2% auto;
      text-align: center;
  
    }
    .descriptionP{
      line-height: 1.5;
      direction: rtl;
      font-family: "Fredoka";
      width:85%;
      font-size: 1.5rem;
      margin:2% auto;
      text-align: center;
      padding-top:80px
    }
  }
  @media only screen  and (min-width: 550px) and (max-width: 650px) {

    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 2.4rem;
      margin:3% auto;
      text-align: center;
      
  }
  .header{
    direction: rtl;
    font-family: "Fredoka";
    font-size: 3rem;
    margin:2% auto;
    text-align: center;
    
    z-index: -1;
    position: relative;
  }
  .bold {
      background:rgb(255, 255, 255);
      display: inline;
      padding: 0.2rem 0.5rem;
      border-radius: 0.5rem;
      color: #050505;
      
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      position: relative;
    }
    
    .bold::before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      background: whitesmoke;
      z-index: -1;
      border-radius: 0.5rem;
      filter: blur(5px);
      opacity: 0.5;
    }
  
    .description{
  line-height: 1.5;
      direction: rtl;
      font-family: "Fredoka";
      width:85%;
      font-size: 1.8rem;
      margin:2% auto;
      text-align: center;
  
    }
    .descriptionP{
      line-height: 1.5;
          direction: rtl;
          font-family: "Fredoka";
          width:85%;
          font-size: 1.8rem;
          margin:2% auto;
          text-align: center;
          padding-top:80px;
      
        }
  }
  @media only screen  and (min-width: 650px) and (max-width: 750px) {

    .title{
      direction: rtl;
      font-family: "Fredoka";
      font-size: 2.5rem;
      margin:3% auto;
      text-align: center;
      
  }
  .header{
    direction: rtl;
    font-family: "Fredoka";
    font-size: 3rem;
    margin:2% auto;
    text-align: center;
    
    z-index: -1;
    position: relative;
  }
  .bold {
      background:rgb(255, 255, 255);
      display: inline;
      padding: 0.2rem 0.5rem;
      border-radius: 0.5rem;
      color: #050505;
      
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      position: relative;
    }
    
    .bold::before {
      content: "";
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      background: whitesmoke;
      z-index: -1;
      border-radius: 0.5rem;
      filter: blur(5px);
      opacity: 0.5;
    }
  
    .description{
  line-height: 1.5;
      direction: rtl;
      font-family: "Fredoka";
      width:85%;
      font-size: 1.7rem;
      margin:2% auto;
      text-align: center;
  
    }
    .descriptionP{
      line-height: 1.5;
          direction: rtl;
          font-family: "Fredoka";
          width:85%;
          font-size: 1.7rem;
          margin:2% auto;
          text-align: center;
          padding-top:80px;
      
        }
  }