.title{
    display: flex;
    width:90%;
    text-align: center;
    font-family: "Assistant";
    direction: rtl;
    margin: 2% auto;
    justify-content: center;
    font-size:2.5rem;
}
.silabus{
margin:2% auto;
    width:650px;
    padding:20px;
    height:auto;
    /* background: linear-gradient(159deg, rgba(255, 255, 255, 0.247) 0%, rgba(255,255,255,1) 20%, rgba(255, 255, 255, 0.252) 40%, rgba(255,255,255,1) 60%, rgba(255, 255, 255, 0.192) 80%, rgba(255,255,255,1) 100%); */
}
.right{
    display: flex;
    width:70%;
    justify-content: end;
    margin-right: auto;
}
.left{
    display: flex;
    width:70%;
    justify-content: start;
    margin-left: auto;
}
@media only screen and (max-width: 350px) {
    .title{
        display: flex;
        width:90%;
        text-align: center;
        font-family: "Assistant";
        direction: rtl;
        margin: 2% auto;
        justify-content: center;
        font-size:2rem;
    }
    .silabus{
    margin:2% auto;
        width:290px;
        padding:20px;
        height:auto;
        /* background: linear-gradient(159deg, rgba(255, 255, 255, 0.247) 0%, rgba(255,255,255,1) 20%, rgba(255, 255, 255, 0.252) 40%, rgba(255,255,255,1) 60%, rgba(255, 255, 255, 0.192) 80%, rgba(255,255,255,1) 100%); */
    }
    .right{
        display: flex;
        width:95%;
        justify-content: end;
        margin-right: auto;
    }
    .left{
        display: flex;
        width:95%;
        justify-content: start;
        margin-left: auto;
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {
    .title{
        display: flex;
        width:90%;
        text-align: center;
        font-family: "Assistant";
        direction: rtl;
        margin: 2% auto;
        justify-content: center;
        font-size:2rem;
    }
    .silabus{
    margin:2% auto;
        width:320px;
        padding:20px;
        height:auto;
        /* background: linear-gradient(159deg, rgba(255, 255, 255, 0.247) 0%, rgba(255,255,255,1) 20%, rgba(255, 255, 255, 0.252) 40%, rgba(255,255,255,1) 60%, rgba(255, 255, 255, 0.192) 80%, rgba(255,255,255,1) 100%); */
    }
    .right{
        display: flex;
        width:95%;
        justify-content: end;
        margin-right: auto;
    }
    .left{
        display: flex;
        width:95%;
        justify-content: start;
        margin-left: auto;
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {
    .title{
        display: flex;
        width:90%;
        text-align: center;
        font-family: "Assistant";
        direction: rtl;
        margin: 2% auto;
        justify-content: center;
        font-size:2rem;
    }
    .silabus{
    margin:2% auto;
        width:400px;
        padding:20px;
        height:auto;
        /* background: linear-gradient(159deg, rgba(255, 255, 255, 0.247) 0%, rgba(255,255,255,1) 20%, rgba(255, 255, 255, 0.252) 40%, rgba(255,255,255,1) 60%, rgba(255, 255, 255, 0.192) 80%, rgba(255,255,255,1) 100%); */
    }
    .right{
        display: flex;
        width:95%;
        justify-content: end;
        margin-right: auto;
    }
    .left{
        display: flex;
        width:95%;
        justify-content: start;
        margin-left: auto;
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px) {
    .title{
        display: flex;
        width:90%;
        text-align: center;
        font-family: "Assistant";
        direction: rtl;
        margin: 2% auto;
        justify-content: center;
        font-size:2rem;
    }
    .silabus{
    margin:2% auto;
        width:400px;
        padding:20px;
        height:auto;
        /* background: linear-gradient(159deg, rgba(255, 255, 255, 0.247) 0%, rgba(255,255,255,1) 20%, rgba(255, 255, 255, 0.252) 40%, rgba(255,255,255,1) 60%, rgba(255, 255, 255, 0.192) 80%, rgba(255,255,255,1) 100%); */
    }
    .right{
        display: flex;
        width:95%;
        justify-content: end;
        margin-right: auto;
    }
    .left{
        display: flex;
        width:95%;
        justify-content: start;
        margin-left: auto;
    }
}