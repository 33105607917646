.center{
  width:35%;
  display: flex;
  justify-content: center;
  margin:15px auto;
}

.button{
  font-family: "Fredoka";
  font-size: 1.2rem;
  background:none;
  backdrop-filter: blur(50px);
  width:auto;
  direction: rtl;
  padding: 15px; /* Add padding */
  cursor: pointer;
  text-decoration: none;
  color:black;
  border:none;
  border-radius: 20px;
  box-shadow: 0 0 7px 1px #444;
  transition: all ease 1s;
}

.button:hover{
transform: scale(1.1);
}

@media only screen and (max-width: 350px) {
.center{
  width:70%;
  display: flex;
  justify-content: center;
  margin:15px auto;
}

.button{
  font-family: "Fredoka";
  font-size: 1.1rem;
  background:none;
  backdrop-filter: blur(50px);
  width: 80%;
  padding: 15px; /* Add padding */
  cursor: pointer;
  border:none;
  border-radius: 20px;
  box-shadow: 0 0 7px 1px #444;
  transition: all ease 1s;
}

.button:hover{
  transform: scale(1.1);
}
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
.center{
  width:60%;
  display: flex;
  justify-content: center;
  margin:15px auto;
}

.button{
  font-family: "Fredoka";
  font-size: 1.1rem;
  background:none;
  backdrop-filter: blur(50px);
  width: 85%;
  padding: 15px; /* Add padding */
  cursor: pointer;
  border:none;
  border-radius: 20px;
  box-shadow: 0 0 7px 1px #444;
  transition: all ease 1s;
}

.button:hover{
  transform: scale(1.1);
}
}

@media only screen and (min-width: 450px) and (max-width: 550px) {
.center{
  width:65%;
  display: flex;
  justify-content: center;
  margin:15px auto;
}

.button{
  font-family: "Fredoka";
  font-size: 1.2rem;
  background:none;
  backdrop-filter: blur(50px);
  width: 70%;
  padding: 15px; /* Add padding */
  cursor: pointer;
  border:none;
  border-radius: 20px;
  box-shadow: 0 0 7px 1px #444;
  transition: all ease 1s;
}

.button:hover{
  transform: scale(1.1);
}
}

@media only screen and (min-width: 550px) and (max-width: 650px) {
.center{
  width:45%;
  display: flex;
  justify-content: center;
  margin:15px auto;
}

.button{
  font-family: "Fredoka";
  font-size: 1.2rem;
  background:none;
  backdrop-filter: blur(50px);
  width: 80%;
  padding: 15px; /* Add padding */
  cursor: pointer;
  border:none;
  border-radius: 20px;
  box-shadow: 0 0 7px 1px #444;
  transition: all ease 1s;
}

.button:hover{
  transform: scale(1.1);
}
}

@media only screen and (min-width: 650px) and (max-width: 950px) {
.center{
  width:40%;
  display: flex;
  justify-content: center;
  margin:15px auto;
}

.button{
  font-family: "Fredoka";
  font-size: 1.2rem;
  background:none;
  backdrop-filter: blur(50px);
  width:70%;
  padding: 15px; /* Add padding */
  cursor: pointer;
  border:none;
  border-radius: 20px;
  box-shadow: 0 0 7px 1px #444;
  transition: all ease 1s;
}

.button:hover{
  transform: scale(1.1);
}
}
@media only screen and (min-width: 950px) and (max-width: 1150px) {
  .center{
    width:35%;
    display: flex;
    justify-content: center;
    margin:15px auto;
  }

  .button{
    font-family: "Fredoka";
    font-size: 1.2rem;
    background:none;
    backdrop-filter: blur(50px);
    width: 100%;
    padding: 15px; /* Add padding */
    cursor: pointer;
    border:none;
    border-radius: 20px;
    box-shadow: 0 0 7px 1px #444;
    transition: all ease 1s;
  }

  .button:hover{
    transform: scale(1.1);
  }
}