.title{
    direction: rtl;
    font-family: "Fredoka";
    font-size: 3rem;
    margin:2% auto;
    color:rgb(66, 13, 116);
    text-align: center;
}
.underline{
    display: inline;
    text-decoration: underline;
}
.bold{
    font-family: "Assistant";
    display: inline;
    
}

.description{
    direction: rtl;
    font-family: "AssistantR";
    font-size: 1.5rem;
    margin:2% auto;
    line-height: 1.8;
    
    text-align: center;
    width:50%;
}

.center{

    display: flex;
    justify-content: center;
    margin:2% auto;
    width:25%;

}
.image{
    width:100%;
    border-radius: 150px 0 150px 0;
    box-shadow: 0 0 5px 1px #222;
    object-fit: contain;
}
.image2{
    width:100%;
    border-radius:0 150px 0 150px ;
    box-shadow: 0 0 5px 1px #222;
    object-fit: contain;
}

@media only screen and (max-width: 350px) {

    .title{
        direction: rtl;
        font-family: "Fredoka";
        font-size: 2rem;
        margin:2% auto;
        color:rgb(66, 13, 116); 
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.15rem;
        margin:2.5% auto;
        line-height: 1.5;
        
        text-align: center;
        width:80%;
    }
    
    .center{
    
        display: flex;
        justify-content: center;
        margin:2.5% auto;
        width:85%;
    }
    .image{
        width:100%;
        border-radius: 75px 0 75px 0;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
    .image2{
        width:100%;
        border-radius:0 75px 0 75px ;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
}
@media only screen and (min-width: 350px)  and (max-width: 450px) {

    .title{
        direction: rtl;
        font-family: "Fredoka";
        font-size: 2.3rem;
        margin:3.5% auto;
        
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.25rem;
        margin:2.5% auto;
        line-height: 1.7;
        
        text-align: center;
        width:85%;
    }
    
    .center{
    
        display: flex;
        justify-content: center;
        margin:2% auto;
        width:70%;
    
    }
    .image{
        width:100%;
        border-radius: 85px 0 85px 0;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
    .image2{
        width:100%;
        border-radius:0 85px 0 85px ;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
}
@media only screen and (min-width: 450px)  and (max-width: 550px) {

    .title{
        direction: rtl;
        font-family: "Fredoka";
        font-size: 2.5rem;
        margin:2.5% auto;
       
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.25rem;
        margin:2.5% auto;
        line-height: 1.5;
        
        text-align: center;
        width:80%;
    }
    
    .center{
    
        display: flex;
        justify-content: center;
        margin:2.5% auto;
        width:60%;
    
    }
    .image{
        width:100%;
        border-radius: 75px 0 75px 0;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
    .image2{
        width:100%;
        border-radius:0 75px 0 75px ;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
}

@media only screen and (min-width: 550px)  and (max-width: 650px) {

    .title{
        direction: rtl;
        font-family: "Fredoka";
        font-size: 2.7rem;
        margin:2% auto;
       
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.25rem;
        margin:2% auto;
        line-height: 1.5;
        
        text-align: center;
        width:80%;
    }
    
    .center{
    
        display: flex;
        justify-content: center;
        margin:2% auto;
        width:50%;
    
    }
    .image{
        width:100%;
        border-radius: 85px 0 85px 0;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
    .image2{
        width:100%;
        border-radius:0 85px 0 85px ;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
    

}
@media only screen and (min-width: 650px)  and (max-width: 850px) {

   
    .title{
        direction: rtl;
        font-family: "Fredoka";
        font-size: 2.7rem;
        margin:2% auto;
       
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.25rem;
        margin:2% auto;
        line-height: 1.5;
        
        text-align: center;
        width:80%;
    }
    
    .center{
    
        display: flex;
        justify-content: center;
        margin:2% auto;
        width:40%;
    
    }
    .image{
        width:100%;
        border-radius: 90px 0 90px 0;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
    .image2{
        width:100%;
        border-radius:0 90px 0 90px ;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
}
@media only screen and (min-width: 850px)  and (max-width: 1050px) {

    
    .title{
        direction: rtl;
        font-family: "Fredoka";
        font-size:3rem;
        margin:2% auto;
       
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.35rem;
        margin:2% auto;
        line-height: 1.5;
        
        text-align: center;
        width:70%;
    }
    
    .center{
    
        display: flex;
        justify-content: center;
        margin:2% auto;
        width:35%;
    
    }
    .image{
        width:100%;
        border-radius: 100px 0 100px 0;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
    .image2{
        width:100%;
        border-radius:0 100px 0 100px ;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }

}
@media only screen and (min-width: 1050px)  and (max-width: 1250px) {

    .title{
        direction: rtl;
        font-family: "Fredoka";
        font-size:3.2rem;
        margin:2% auto;
       
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.4rem;
        margin:2% auto;
        line-height: 1.5;
        
        text-align: center;
        width:60%;
    }
    
    .center{
    
        display: flex;
        justify-content: center;
        margin:2% auto;
        width:30%;
    
    }
    .image{
        width:100%;
        border-radius: 120px 0 120px 0;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }
    .image2{
        width:100%;
        border-radius:0 120px 0 120px ;
        box-shadow: 0 0 5px 1px #222;
        object-fit: contain;
    }

}