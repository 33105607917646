.box {
    position: relative;
    background: rgba(255, 255, 255, 0.2); /* הוסף עיצוב של זכוכית */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* הוסף צל לקופסא */
    width:300px;
    margin:2% auto;
  cursor: pointer;
    height:150px
  }
  
  .circle {
    position: absolute;
    top: -10px;
    right: 0;

cursor: pointer;

    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  .icon{
    width: 100%;
    position: relative;
    
  }
  .title{
    direction: rtl;
    font-family: "Assistant";
    font-size: 1.8rem;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    margin:3% auto;
    position: absolute;
 
    top:50px;
    left:50%;
    color:rgb(75, 5, 140);
    width: 100%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .description{
    direction: rtl;
    font-family: "Assistant";
    font-size:1.2rem;
    margin:3% auto;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    left:50%;
   
    transform: translate(-50%, -50%);
    position: absolute;
    top:100px;
width: 100%;
    text-align: center;
  }
  .little{
    direction: rtl;
    font-family: "Assistant";
    font-size:1rem;
    margin:3% auto;
    left:50%;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%);
    position: absolute;
    top:140px;
width: 100%;
    text-align: center;
  }
  @media only screen and (max-width: 350px) {

    .box {
      position: relative;
      background: rgba(255, 255, 255, 0.2); /* הוסף עיצוב של זכוכית */
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* הוסף צל לקופסא */
      width:250px;
      margin:3% auto;
  
      height:150px
    }
    
    .circle {
      position: absolute;
      top: -10px;
      right: 0;
  
  cursor: pointer;
  
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
     
    }
    .icon{
      width: 100%;
    }
    .title{
      direction: rtl;
      font-family: "Assistant";
      font-size: 1.6rem;
      margin:3% auto;
      position: absolute;
      top:50px;
      left:50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .description{
      direction: rtl;
      font-family: "Assistant";
      font-size:1.15rem;
 
      margin:3% auto;
      left:50%;
      transform: translate(-50%, -50%);
      position: absolute;
      top:100px;
  width: 100%;
      text-align: center;
    }
    .little{
      direction: rtl;
      font-family: "Assistant";
      font-size:0.9rem;
      margin:3% auto;
      left:50%;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      transform: translate(-50%, -50%);
      position: absolute;
      top:140px;
  width: 100%;
      text-align: center;
    }
  }
  @media only screen  and (min-width: 350px) and (max-width: 450px) {

    .box {
      position: relative;
      background: rgba(255, 255, 255, 0.2); /* הוסף עיצוב של זכוכית */
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* הוסף צל לקופסא */
      width:300px;
      margin:3% auto;
  
      height:150px
    }
    
    .circle {
      position: absolute;
      top: -10px;
      right: 0;
  
  cursor: pointer;
  
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
     
    }
    .title{
      direction: rtl;
      font-family: "Assistant";
      font-size: 1.8rem;
      margin:3% auto;
      position: absolute;
      top:50px;
      left:50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .description{
      direction: rtl;
      font-family: "Assistant";
      font-size:1.3rem;
      margin:3% auto;
      left:50%;
      transform: translate(-50%, -50%);
      position: absolute;
      top:100px;
  width: 100%;
      text-align: center;
    }
    .little{
      direction: rtl;
      font-family: "Assistant";
      font-size:1.05rem;
      margin:3% auto;
      left:50%;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      transform: translate(-50%, -50%);
      position: absolute;
      top:140px;
  width: 100%;
      text-align: center;
    }
  }

  @media only screen  and (min-width: 450px) and (max-width: 550px) {

    .box {
      position: relative;
      background: rgba(255, 255, 255, 0.2); /* הוסף עיצוב של זכוכית */
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* הוסף צל לקופסא */
      width:340px;
      margin:3% auto;
  
      height:180px
    }
    
    .circle {
      position: absolute;
      top: -15px;
      right: 0;
  
  cursor: pointer;
  
      border-radius: 50%;
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
     
    }
    .icon{
      width: 100%;
    }
    .title{
      direction: rtl;
      font-family: "Assistant";
      font-size: 2rem;
      margin:3% auto;
      position: absolute;
      top:50px;
      left:50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .description{
      direction: rtl;
      font-family: "Assistant";
      font-size:1.4rem;
      margin:3% auto;
      left:50%;
      transform: translate(-50%, -50%);
      position: absolute;
      top:100px;
  width: 100%;
      text-align: center;
    }
    .little{
      direction: rtl;
      font-family: "Assistant";
      font-size:1.1rem;
      margin:3% auto;
      left:50%;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      transform: translate(-50%, -50%);
      position: absolute;
      top:140px;
  width: 100%;
      text-align: center;
    }
  }
  @media only screen  and (min-width: 550px) and (max-width: 650px) {

    .box {
      position: relative;
      background: rgba(255, 255, 255, 0.2); /* הוסף עיצוב של זכוכית */
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* הוסף צל לקופסא */
      width:340px;
      margin:3% auto;
  
      height:150px
    }
    
    .circle {
      position: absolute;
      top: -15px;
      right: 0;
  
  cursor: pointer;
  
      border-radius: 50%;
      width: 70px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
     
    }
    .icon{
      width: 100%;
    }
    .title{
      direction: rtl;
      font-family: "Assistant";
      font-size: 2rem;
      margin:3% auto;
      position: absolute;
      top:50px;
      left:50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .description{
      direction: rtl;
      font-family: "Assistant";
      font-size:1.4rem;
      margin:3% auto;
      left:50%;
      transform: translate(-50%, -50%);
      position: absolute;
      top:100px;
  width: 100%;
      text-align: center;
    }
    .little{
      direction: rtl;
      font-family: "Assistant";
      font-size:1.1rem;
      margin:3% auto;
      left:50%;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      transform: translate(-50%, -50%);
      position: absolute;
      top:140px;
  width: 100%;
      text-align: center;
    }
  }
  @media only screen  and (min-width: 650px) and (max-width: 750px) {

    .box {
      position: relative;
      background: rgba(255, 255, 255, 0.2); /* הוסף עיצוב של זכוכית */
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* הוסף צל לקופסא */
      width:250px;
      margin:3% auto;
  
      height:150px
    }
    
    .circle {
      position: absolute;
      top: -10px;
      right: 0;
  
  cursor: pointer;
  
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
     
    }
    .icon{
      width: 100%;
    }
    .title{
      direction: rtl;
      font-family: "Assistant";
      font-size: 1.6rem;
      margin:3% auto;
      position: absolute;
      top:50px;
      left:50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .description{
      direction: rtl;
      font-family: "Assistant";
      font-size:1.15rem;
      margin:3% auto;
      left:50%;
      transform: translate(-50%, -50%);
      position: absolute;
      top:100px;
  width: 100%;
      text-align: center;
    }
    .little{
      direction: rtl;
      font-family: "Assistant";
      font-size:1rem;
      margin:3% auto;
      left:50%;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      transform: translate(-50%, -50%);
      position: absolute;
      top:140px;
  width: 100%;
      text-align: center;
    }
  }
  @media only screen  and (min-width: 750px) and (max-width: 850px) {

    .box {
      position: relative;
      background: rgba(255, 255, 255, 0.2); /* הוסף עיצוב של זכוכית */
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* הוסף צל לקופסא */
      width:280px;
      margin:3% auto;
  
      height:160px
    }
    
    .circle {
      position: absolute;
      top: -10px;
      right: 0;
  
  cursor: pointer;
  
      border-radius: 50%;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
     
    }
    .icon{
      width: 100%;
    }
    .title{
      direction: rtl;
      font-family: "Assistant";
      font-size: 1.8rem;
      margin:3% auto;
      position: absolute;
      top:50px;
      left:50%;
      width: 100%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    .description{
      direction: rtl;
      font-family: "Assistant";
      font-size:1.25rem;
      margin:3% auto;
      left:50%;
      transform: translate(-50%, -50%);
      position: absolute;
      top:100px;
  width: 100%;
      text-align: center;
    }
    .little{
      direction: rtl;
      font-family: "Assistant";
      font-size:1.1rem;
      margin:3% auto;
      left:50%;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      transform: translate(-50%, -50%);
      position: absolute;
      top:140px;
  width: 100%;
      text-align: center;
    }
  }