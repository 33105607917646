@import url("./fonts/font.css");

body{
/* background: linear-gradient(159deg, rgba(183,115,242,0.3501050078234419) 0%, rgba(192,155,213,1) 20%, rgba(150,255,254,0.34730388737526263) 40%, rgba(255,255,196,1) 60%, rgba(189,72,237,0.34730388737526263) 80%, rgba(221,255,252,1) 100%); */
/* background: linear-gradient(162deg, rgba(255,255,227,1) 0%, rgba(180,214,236,1) 10%, rgba(255,255,227,1) 20%, rgba(180,214,236,1) 30%, rgba(243,227,255,1) 40%, rgba(180,214,236,1) 50%, rgba(243,227,255,1) 60%, rgba(180,214,236,1) 70%, rgba(227,255,252,1) 80%, rgba(180,214,236,1) 90%, rgba(227,255,252,1) 100%);
 */
 background: linear-gradient(162deg, rgba(243,227,255,1) 0%, rgba(240,203,155,0.7) 10%, rgba(162,101,193,0.7) 20%, rgba(240,203,155,0.7) 30%, rgba(255,255,227,1) 40%, rgba(240,203,155,0.7) 50%, rgba(255,255,227,1) 60%, rgba(162,101,193,0.7) 70%, rgba(240,203,155,0.7) 80%, rgba(162,101,193,0.7) 90%, rgba(240,203,155,0.7) 100%);
}
.background{
    overflow: hidden;
}