.title{
    direction: rtl;
    font-family: "Assistant";
    font-size: 4rem;
    margin:2% auto;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    text-align: center;
    color:rgb(75, 5, 140);
}
.description{
    direction: rtl;
    font-family: "Assistant";
    font-size: 2.5rem;
    margin:2% auto;
    color:rgb(75, 5, 140);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    text-align: center;
}
.more{
    direction: rtl;
    font-family: "AssistantR";
    font-size: 1.4rem;
    margin:2% auto;
    width:60%;
    text-align: center;
}
.center{

    display: flex;
    justify-content: center;
    margin:2% auto;
}

@media only screen and (max-width: 350px) {

    .title{
        direction: rtl;
        font-family: "Assistant";
        font-size: 2rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "Assistant";
        font-size:1.4rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .more{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.1rem;
        margin:2% auto;
        width:85%;
        text-align: center;
    }
    .center{
    width:95%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    
    .image{
        object-fit: contain;
        width: 100%;;
    }

}
@media only screen and (min-width: 350px)  and (max-width: 450px) {

    .title{
        direction: rtl;
        font-family: "Assistant";
        font-size: 2.2rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "Assistant";
        font-size:1.5rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .more{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.2rem;
        margin:2% auto;
        width:85%;
        text-align: center;
    }
    .center{
    width:95%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    
    .image{
        object-fit: contain;
        width: 100%;;
    }
}
@media only screen and (min-width: 450px)  and (max-width: 550px) {

    .title{
        direction: rtl;
        font-family: "Assistant";
        font-size: 2.5rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "Assistant";
        font-size:1.6rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .more{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.15rem;
        margin:2% auto;
        width:85%;
        text-align: center;
    }
    .center{
    width:95%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    
    .image{
        object-fit: contain;
        width: 100%;;
    }
}

@media only screen and (min-width: 550px)  and (max-width: 650px) {

    .title{
        direction: rtl;
        font-family: "Assistant";
        font-size: 2.5rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "Assistant";
        font-size:1.6rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .more{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.15rem;
        margin:2% auto;
        width:85%;
        text-align: center;
    }
    .center{
    width:85%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    
    .image{
        object-fit: contain;
        width: 100%;
    }
}
@media only screen and (min-width: 650px)  and (max-width: 850px) {

    .title{
        direction: rtl;
        font-family: "Assistant";
        font-size: 2.8rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "Assistant";
        font-size:2rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .more{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.2rem;
        margin:2% auto;
        width:85%;
        text-align: center;
    }
    .center{
    width:65%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    
    .image{
        object-fit: contain;
        width: 100%;
    }
}
@media only screen and (min-width: 850px)  and (max-width: 1050px) {

    .title{
        direction: rtl;
        font-family: "Assistant";
        font-size: 3rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "Assistant";
        font-size:2.2rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .more{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.25rem;
        margin:2% auto;
        width:85%;
        text-align: center;
    }
    .center{
    width:55%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    
    .image{
        object-fit: contain;
        width: 100%;
    }
}
@media only screen and (min-width: 1050px)  and (max-width: 1250px) {

    .title{
        direction: rtl;
        font-family: "Assistant";
        font-size: 3.2rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
        text-align: center;
    }
    .description{
        direction: rtl;
        font-family: "Assistant";
        font-size:2.4rem;
        margin:2% auto;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
        text-align: center;
    }
    .more{
        direction: rtl;
        font-family: "AssistantR";
        font-size: 1.25rem;
        margin:2% auto;
        width:60%;
        text-align: center;
    }
    .center{
    width:55%;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    
    .image{
        object-fit: contain;
        width: 100%;
    }
}